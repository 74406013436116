import React from "react";
import PropTypes from "prop-types";
import {useStaticQuery, graphql} from "gatsby";


const InnerBanner = ({ image, currentPageTitle, currentPageDescription }) => {

    const data = useStaticQuery(graphql`query BannerImages2{
                                            bannerOne: file(relativePath: { eq: "banner/banner-1.jpg" }) {
                                                        childImageSharp {
                                                            fluid(jpegQuality: 100, maxWidth: 2048) {
                                                                ...GatsbyImageSharpFluid
                                                            }
                                                        }
                                                    }
                                            }
                                        `)
                                        console.log(data.bannerOne.childImageSharp.fluid)

    return (
        
        <div className="banner">
            <div className="relative w-full bg-fixed bg-center" style={{ backgroundImage: `url(${data.bannerOne.childImageSharp.fluid.src})`, height: '60vh' }}>
                <div className="banner_text absolute z-20 top-0 left-0 w-full h-full flex flex-col items-center justify-center">
                    <div className="banner_text-one text-center text-white md:mb-40 lg:mb-64">
                        <h1 className="text-2xl font-semibold sm:text-4xl lg:text-6xl mt-40">{currentPageTitle}</h1>
                        <h2 className="text-lg font-light sm:text-xl lg:text-4xl">{currentPageDescription}</h2>
                    </div>
                </div>
            </div>
        </div>
    )

}

InnerBanner.defaultProps = {
    currentPageDescription: ""
}
  
InnerBanner.propTypes = {
    image: PropTypes.string.isRequired,
    currentPageTitle: PropTypes.any.isRequired,
    currentPageDescription: PropTypes.any
}

export default InnerBanner;