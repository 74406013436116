import React from "react"

import Layout from "../components/layouts/layout/layout"
import InnerBanner from "../components/others/inner_banner"
import Terms from "./../components/others/terms";

const TermsOfServicePage = () => (
  <Layout>
    <InnerBanner currentPageTitle={"Terms of Service"} currentPageDescription={""} />
    <Terms />
  </Layout>
)

export default TermsOfServicePage
